export default function addRedNumber() {
  const numbers = document.querySelectorAll("[js-number]");
  
  if (!numbers.length) return;

  numbers.forEach((number) => {
    const moneyR$ = number.querySelector("span");
    
    // Remove caracteres indesejados, preservando números, pontos e vírgulas
    let str = number.innerText.replace(/[^0-9,-]+/g, "").trim();
    
    // Substitui a vírgula decimal por ponto
    str = str.replace(",", ".");
    
    // Remove os pontos que são separadores de milhar
    str = str.replace(/\.(?=\d{3})/g, "");

    // Converte a string para número
    const numero = parseFloat(str);
    
    // Verifica se o número é válido
    if (isNaN(numero)) return;

    // Formata o número com duas casas decimais
    let formatado = Math.abs(numero).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Adiciona o símbolo R$ antes do número e trata o caso de número negativo
    if (numero < 0) {
      formatado = `R$ -${formatado}`;
      number.style.color = "#CD0F0F";
      moneyR$?.classList.remove("text-neutral-50");
    } else {
      formatado = `R$ ${formatado}`;
    }

    // Atualiza o conteúdo do elemento com o número formatado
    number.innerText = formatado;

    console.log({
      number: number.innerText,
      str,
      numero,
      formatado,
    });
  });
}

export default function initFloatButton() {
    const btnDropdown = document.querySelector('#btn-float')
    const dropdownMenu = document.querySelector('#dropdownMenu')
    const iconPlus = btnDropdown?.querySelector('#icon-plus')

    if (!btnDropdown || !dropdownMenu || !iconPlus) return

    const animate = () => {
        if (dropdownMenu.classList.contains('hidden')) {
            gsap.fromTo(dropdownMenu, { opacity: 0, y: 20 }, { duration: 0.2, opacity: 1, y: 0 });
            dropdownMenu.classList.remove('hidden')
            dropdownMenu.classList.add('flex')
            gsap.to(iconPlus, { duration: 0.3, rotate: 45 })
        } else {
            gsap.to(dropdownMenu, {
                duration: 0.3, opacity: 0, y: 20, onComplete: function () {
                    dropdownMenu.classList.add('hidden')
                    dropdownMenu.classList.remove('flex')
                }
            });
            gsap.to(iconPlus, { duration: 0.2, rotate: 0 })

        }

    }

    window.addEventListener('click', ({ target }) => {
        
        if (target.id === 'btn-float') {
            animate({ currentTarget: target })
        } else {
            if (!dropdownMenu.classList.contains('hidden')) {
                animate()
            }
        }
    })
}